@tailwind base;
@tailwind components;
@tailwind utilities;

.arrow {
    opacity: 0.1;
}

.arrow.active {
    opacity: 0.3;
}

.arrow:hover {
    opacity: 0.5;
}

.svg-container {
    width: 100%;
    overflow: scroll;
}


input[type=checkbox]
{
    /* Double-sized Checkboxes */
    -ms-transform: scale(1); /* IE */
    -moz-transform: scale(1); /* FF */
    -webkit-transform: scale(1); /* Safari and Chrome */
    -o-transform: scale(1); /* Opera */
    transform: scale(1);
    padding: 3px;
}
@media (min-width: 380px) {
    input[type=checkbox]{
      padding: 8px;
      -ms-transform: scale(2); /* IE */
      -moz-transform: scale(2); /* FF */
      -webkit-transform: scale(2); /* Safari and Chrome */
      -o-transform: scale(2); /* Opera */
      transform: scale(2);

    } 
}

rect, rect:focus { outline: none }

polygon, polygon:focus { outline: none }

